<template>
  <div class="container">
      <div class="header">
        <div class="picture">
          <img
            class="user-icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/user.png"
          />
        </div>
        <div class="header-cont">
          <!-- <div class="name">李默默</div> -->
          <div class="phone">
            <img
              class="icon"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/phone.png"
            />
            <div class="number">{{ changXueSupreme_info.phone }}</div>
          </div>
        </div>
        <div class="setting" @click="handleLogOut">
          <img
            class="setting-icon"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/user_icon.png"
          />
          <div class="text">切换账号</div>
        </div>
      </div>
      <div class="box">
        <div class="line" @click="handleClick">
          <img
            class="picture"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/historyIcon.png"
          />
          <div class="cont">
            <div class="name">权益领取记录</div>
            <div class="tag">点击这里查看您已领取的权益</div>
          </div>
          <img
            class="more"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/more.png"
          />
        </div>
      </div>

    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from "@/components/BaseFooter/index";
import { mapActions, mapGetters } from "vuex"; 

export default {
  components: {
    BaseFooter,
  },
  data() {
    return {
      data: ["", ""],
    };
  },
  computed: {
    ...mapGetters(["changXueSupreme_info"]),
  },
  mounted() {
    const {phone = ''} = this.changXueSupreme_info
    if(!phone) {
      this['changXueSupreme/getUserInfo']();
    }
  },
  methods: {
     ...mapActions(['changXueSupreme/getUserInfo', 'changXueSupreme/logOut']),
    handleLogOut() {
     this['changXueSupreme/logOut']();
      this.$router.replace("/changXueSupreme/receive/login")
    },
    handleClick() {
      this.$router.push("/changXueSupreme/receive/record");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;


    .header {
      height: 255px;
      background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/my_top.png")
        center center/cover no-repeat;
      display: flex;
      padding: 0 0 42px 79px;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      .picture {
        width: 114px;
        height: 114px;
        display: flex;
        align-content: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 54px;
        overflow: hidden;

        .user-icon {
          width: 100%;
        }
      }

      &-cont {
        color: #fff;
        padding-left: 22px;
        flex: 1;

        .name {
          font-size: 36px;
          color: #ffffff;
          line-height: 50px;
          padding-bottom: 14px;
        }

        .phone {
          display: flex;
          align-items: center;
          .icon {
            width: 24px;
            height: 24px;
          }

          .number {
            padding-left: 5px;
            font-weight: 400;
            font-size: 32px;
            color: #ffffff;
            line-height: 33px;
          }
        }
      }

      .setting {
        width: 158px;
        height: 46px;
        background: rgba(0, 119, 240, 0.9);
        // border-radius: 23px;
        border-top-left-radius: 23px;
        border-bottom-left-radius: 23px;
        font-weight: 400;
        font-size: 22px;
        color: #cce4fc;
        display: flex;
        justify-content: center;
        align-items: center;
        &-icon {
          width: 20px;
          height: 26px;
        }

        .text {
          padding-left: 12px;
        }
      }
    }
    .box {
      position: absolute;
      left: 0;
      right: 0;
      top: 212px;
      bottom: 130px;
      background: #f6f6f6;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 40px 30px;

      .line {
        height: 138px;
        background: #ffffff;
        border-radius: 20px;
        display: flex;
        padding: 0 24px;
        justify-content: space-between;
        align-items: center;

        .picture {
          width: 72px;
          height: 72px;
        }
        .more {
          width: 44px;
          height: 44px;
        }

        .cont {
          padding-left: 24px;
          flex: 1;
          .name {
            font-weight: 400;
            font-size: 34px;
            color: #333333;
            line-height: 48px;
          }
          .tag {
            padding-top: 4px;
            font-weight: 400;
            font-size: 26px;
            color: #999999;
            line-height: 37px;
          }
        }
      }
    }
  
}
</style>
