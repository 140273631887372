<template>
   <div class="footer">
      <van-tabbar route safe-area-inset-bottom >
    <van-tabbar-item  v-for="item,index in tabbarList" :key="index" replace :to="item.route">
      <span>{{item.name }}</span>
      <template #icon="props">
        <img :src="active == item.route ? item.fillimg : item.img" />
      </template>
    </van-tabbar-item>
 
 
</van-tabbar>
  </div>
</template>

<script>
export default {
  props: {
    tabbarList: {
      type: Array,
      default: () => [
        {
          name:"权益",
          fillimg:'https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/tabar_homefill.png',
          img:'https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/tabar_home.png',
          route: "/changXueSupreme/receive/homePage"
        },
        {
          name:"我的",
          fillimg:'https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/tabar_myfill.png',
          img:'https://cdn.qiumo.net.cn/allsmarketmaterial/changXueSupreme/tabar_my.png',
          route: "/changXueSupreme/receive/my"
        }
     ]
    }
  },
  mounted() {
    this.active = this.$route.path
  },
  data() {
    return {
      active : "",
    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-tabbar {
  height: 130px;
.van-tabbar-item {
  font-size: 20px;

  &__icon img {
    width: 48px;
    height: 48px;
  }

  &__text {
    line-height: 28px;
  }
  &--active {
    color: #01BEFD;
  }
}
}

</style>